import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBandcamp,
  faFacebook,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import "./Navbar.css";
import { useMediaQuery } from "@mui/material";

function Navbar() {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {/*}
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            BEYONDERS MUSIC <div className="bmlogo"></div>
          </Link>
          <div className="navbar-menu-icon">
            {!click ? (
              <MenuIcon onClick={handleClick} />
            ) : (
              <CloseIcon onClick={handleClick} />
            )}
          </div>
          <ul className={click ? "navbar-menu active" : "navbar-menu"}>
            <li>Home</li>
          </ul>
        </div>
      </nav>
      */}

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
            }
            {isNonMobile && (
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                BEYONDERS MUSIC
              </Typography>
            )}

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}><Link to="../" style={{textDecoration:"none",color:"white"}}>Home</Link></MenuItem>
              <MenuItem onClick={handleClose}><Link to="../releases" style={{textDecoration:"none",color:"white"}}>Releases</Link></MenuItem>
              {/*<MenuItem onClick={handleClose}><Link to="../news" style={{textDecoration:"none",color:"white"}}>News</Link></MenuItem>
            <MenuItem onClick={handleClose}><Link to="../gallery" style={{textDecoration:"none",color:"white"}}>Galllery</Link></MenuItem>*/}
            <MenuItem onClick={handleClose}><Link to="../contact" style={{textDecoration:"none",color:"white"}}>Contact</Link></MenuItem>
              <MenuItem onClick={handleClose}><Link to="../about" style={{textDecoration:"none",color:"white"}}>About</Link></MenuItem>
            </Menu>

            <div>
              
              <IconButton
                size="large"
                aria-label="Instagram"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <a
                  href="https://www.instagram.com/beyondersmusic/"
                  target="_blank"
                  style={{ color: "#FFFFFF" }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </IconButton>
              <IconButton
                size="large"
                aria-label="You Tube"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <a
                  href="https://www.youtube.com/@beyondersmusic/featured"
                  target="_blank"
                  style={{ color: "#FFFFFF" }}
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </IconButton>
              <IconButton
                size="large"
                aria-label="Sound Cloud"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <a
                  href="https://soundcloud.com/beyondersmusic"
                  target="_blank"
                  style={{ color: "#FFFFFF" }}
                >
                  <FontAwesomeIcon icon={faSoundcloud} />
                </a>
              </IconButton>

              <IconButton
                size="large"
                aria-label="Sound Cloud"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <a
                  href="https://beyondersmusic.bandcamp.com/"
                  target="_blank"
                  style={{ color: "#FFFFFF" }}
                >
                  <FontAwesomeIcon icon={faBandcamp} />
                </a>
              </IconButton>

              <IconButton
                size="large"
                aria-label="Spotify"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <a
                  href="https://open.spotify.com/artist/79SJixZ4gSHFm03K0Ye72l?si=SAxU_DOnS3uFu1nmn4zanw"
                  target="_blank"
                  style={{ color: "#FFFFFF" }}
                >
                  <FontAwesomeIcon icon={faSpotify} />
                </a>
              </IconButton>

              {/*
            <div className="bmlogo"></div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              
            */}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

export default Navbar;
