import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      //console.log(getState().global);
      const token = getState().global.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  reducerPath: "api",
  tagTypes: [
  ],
  endpoints: (build) => ({}),
})


export const {

} = api;
