import { Box, Container, Grid } from "@mui/material";
import LinkList from "../components/LinkList";
import about_hero from "../images/about_hero.jpeg"

function About() {
  return (
    <>
      <Container
        sx={{
          pt: { xs: 8, sm: 0 },
          minHeight: { xs: "auto", sm: 500 },
          height: { md: "calc(100vh - 120px)" },
          maxHeight: { md: 700, xl: 850 },
          transition: "0.3s",
        }}
      >
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="top"
          justifyContent="left"
          sx={{ minHeight: "80vh", paddingTop: "5vh" }}
        >
            <Grid item  xs={12} sx={{ maxHeight: "100%" }}>
                <h1>About</h1>
            </Grid>
            <Grid item  xs={12} md={6} lg={6} sx={{ maxHeight: "100%" }}>
                <img src={about_hero} width={"100%"}></img>
            </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ maxHeight: "100%", fontSize:"12pt" }}>
            <p style={{textAlign:"justify"}}>
              Beyonders are a collective of artists known for their
              multi-genre approach to experimental music.
              <br />
              The group includes Jason Goodings (NØS/Hidden Agenda), James
              Hadfield (J1Mi/Lizards), Malcolm Sharp (M4LA/Craggz & Parallel
              Forces), and Nick Grimes (Gr1mes/Jolly Damper/The Din).
            </p>
            <p style={{textAlign:"justify"}}>
              With a core ethos rooted in diversity, their individual influences
              span a broad spectrum. Beyonders are introducing themselves through
              a series of EPs, each showcasing the distinct musical landscapes
              of the individual members. This approach allows listeners to
              become acquainted with the unique characters behind the music.
              As they move forward, all new music will be released under the
              unified name of ‘Beyonders’. 
            </p>
            <p style={{textAlign:"justify"}}>
              The 'Beyonders Music' label embodies the spirit of collaboration, with all members 
              participating in the writing process for each release. Those who follow can
              look forward to the collective’s album, which is expected to debut
              in autumn 2024.
            </p>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default About;
