import { Box, Container, Grid } from "@mui/material";
import LinkList from "../components/LinkList";
import { Card, CardContent } from "@mui/material";
import Kaizen_Promo_MK_IV from "../videos/Kaizen_Promo_MK_IV.mp4";
import Case_Edits_Vol_4 from "../videos/Case_Edits_Vol_4.mp4";
import Flame_EP_Promo from "../videos/Flame_EP_Promo.mp4";
function Home() {
  return (
    <>
      <div className="bmbackground">
        <Container
          sx={{
            pt: { xs: 8, sm: 0 },
            minHeight: { xs: "auto", sm: 500 },
            height: { md: "calc(100vh - 120px)" },
            maxHeight: { md: 700, xl: 850 },
            transition: "0.3s",
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="top"
            justifyContent="center"
            sx={{ minHeight: "80vh", paddingTop: "1vh" }}
          >
            <Grid item xs={12} sx={{ maxHeight: "100%" }}>
              <Card
                variant="outlined"
                sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
              >
                <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="top"
                    justifyContent="center"
                    sx={{ minHeight: "10vh", padding: "0.5vh" }}
                  >
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <video
                        controls
                        autoPlay
                        muted
                        style={{ maxHeight: "80vh", maxWidth: "80vw" }}
                      >
                        <source src={Flame_EP_Promo}></source>
                      </video>
                    </Grid>
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <a
                        href="https://orcd.co/y9xmpan"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        OUT NOW: Gr1mes (Beyonders) - Flame EP
                      </a>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sx={{ maxHeight: "100%" }}>
              <Card
                variant="outlined"
                sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
              >
                <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="top"
                    justifyContent="center"
                    sx={{ minHeight: "10vh", padding: "0.5vh" }}
                  >
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <video
                        controls
                        autoPlay
                        muted
                        style={{ maxHeight: "80vh", maxWidth: "80vw" }}
                      >
                        <source src={Case_Edits_Vol_4}></source>
                      </video>
                    </Grid>
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <a
                        href="https://beyondersmusic.bandcamp.com/album/case-edits-vol-4"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        OUT NOW: Case Edits Volume 4
                      </a>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sx={{ maxHeight: "100%" }}>
              <Card
                variant="outlined"
                sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
              >
                <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="top"
                    justifyContent="center"
                    sx={{ minHeight: "10vh", padding: "0.5vh" }}
                  >
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <video
                        controls
                        autoPlay
                        muted
                        style={{ maxHeight: "80vh", maxWidth: "80vw" }}
                      >
                        <source src={Kaizen_Promo_MK_IV}></source>
                      </video>
                    </Grid>
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <a
                        href="https://orcd.co/ajr6q98"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        OUT NOW: Matt Kenny - Up The Ante
                      </a>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/*}
          <Grid item xs={3}>
            <LinkList />
          </Grid>
         */}
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Home;
