import { Box, Grid } from "@mui/material";
import LinkList from "../components/LinkList";

function Gallery() {
  return (
    <>
      <div className="bmbackground">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="top"
          justifyContent="left"
          sx={{ minHeight: "80vh" ,paddingTop:"5vh"}}
        >
          <h1>Gallery</h1>
        </Grid>
      </div>
    </>
  );
}

export default Gallery;
