import { Container, Grid } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import case_edits_logo from "../images/case_edits_logo.jpg";
import beyonders_logo from "../images/beyonders_logo.png";
import loose_agents_logo from "../images/loose_agents_logo.png";
function Releases() {
  return (
    <>
      <Container
        sx={{
          pt: { xs: 8, sm: 0 },
          minHeight: { xs: "auto", sm: 500 },
          height: { md: "calc(100vh - 120px)" },
          maxHeight: { md: 700, xl: 850 },
          transition: "0.3s",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="top"
          justifyContent="left"
          sx={{ minHeight: "80vh", paddingTop: "5vh" }}
        >
          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <h1>Releases</h1>
          </Grid>
          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <Card
              variant="outlined"
              sx={{ backgroundColor: "rgba(0,0,0,0.7)"}}
            >
              <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                <a href="https://beyondersmusic.bandcamp.com/" target="_blank" style={{ textDecoration: "none", color: "white" }}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="top"
                    justifyContent="left"
                    sx={{ minHeight: "20vh", padding: "0.5vh" }}
                  >
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <h2 style={{borderBottom:"1px solid #555"}}>Beyonders Music</h2>
                    </Grid>

                    <Grid item xs={6} md={4} sx={{ maxHeight: "100%" }}>
                      <img
                        src={beyonders_logo}
                        style={{ height: "15vh" }}
                      ></img>
                    </Grid>
                    <Grid item xs={6} md={8} sx={{ maxHeight: "100%" }}>
                    <p style={{fontSize:"2vh"}}>Beyonders Music label, experimental story, music beyond genre...</p>

                    </Grid>
                  </Grid>
                </a>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <Card
              variant="outlined"
              sx={{ backgroundColor: "rgba(0,0,0,0.7)"}}
            >
              <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                <a href="https://looseagents.bandcamp.com/" target="_blank" style={{ textDecoration: "none", color: "white" }}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="top"
                    justifyContent="left"
                    sx={{ minHeight: "20vh", padding: "0.5vh" }}
                  >
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <h2 style={{borderBottom:"1px solid #555"}}>Loose Agents</h2>
                    </Grid>

                    <Grid item xs={6} md={4} sx={{ maxHeight: "100%" }}>
                      <img
                        src={loose_agents_logo}
                        style={{ height: "15vh" }}
                      ></img>
                    </Grid>
                    <Grid item xs={6} md={8} sx={{ maxHeight: "100%" }}>
                      <p style={{fontSize:"2vh"}}>Loose Agents is an affiliated label of Beyonders Music. Founded by J1mi, it curates 
                      an vibrant, eclectic mix of beats that resonate with dance music enthusiasts. The label offers a 
                      platform for artists to experiment and evolve the boundaries of dance music.</p>

                    </Grid>
                  </Grid>
                </a>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <Card
              variant="outlined"
              sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            >
              <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                <a href="https://beyondersmusic.bandcamp.com/" target="_blank" style={{ textDecoration: "none", color: "white" }}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="top"
                    justifyContent="left"
                    sx={{ minHeight: "20vh", padding: "0.5vh" }}
                  >
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <h2 style={{borderBottom:"1px solid #555"}}>Case Edits</h2>
                    </Grid>

                    <Grid item xs={6} md={4} sx={{ maxHeight: "100%" }}>
                      <img
                        src={case_edits_logo}
                        style={{ height: "15vh" }}
                      ></img>
                    </Grid>
                    <Grid item xs={6} md={8} sx={{ maxHeight: "100%" }}>
                    <p style={{fontSize:"2vh"}}>Case Edits, a sub-label under the umbrella of Beyonders Music, 
                    is a creative hub that explores the art of edits. Affiliated artists reimagine, redefine, rework and 
                    blend different genres to create fresh, dynamic tracks.</p>
                    </Grid>
                  </Grid>
                </a>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Releases;
