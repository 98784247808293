import { Container, Grid } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import case_edits_logo from "../images/case_edits_logo.jpg";
import beyonders_logo from "../images/beyonders_logo.png";
import loose_agents_logo from "../images/loose_agents_logo.png";
import joe_haydon_logo from "../images/joe_haydon_logo.png";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
function Contact() {
  return (
    <>
      <Container
        sx={{
          pt: { xs: 8, sm: 0 },
          minHeight: { xs: "auto", sm: 500 },
          height: { md: "calc(100vh - 120px)" },
          maxHeight: { md: 700, xl: 850 },
          transition: "0.3s",
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="top"
          justifyContent="left"
          sx={{ minHeight: "80vh", paddingTop: "5vh" }}
        >
          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <h1>Contacts</h1>
          </Grid>
          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <Card
              variant="outlined"
              sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            >
              <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                <a
                  href="mailto:info@beyondersmusic.com?subject=Beyonders Music"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="top"
                    justifyContent="left"
                    sx={{ minHeight: "10vh", padding: "0.5vh" }}
                  >
                    <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                      <h2 style={{ borderBottom: "1px solid #555" }}>
                        Beyonders Music
                      </h2>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ maxHeight: "100%" }}>
                      <img src={beyonders_logo} style={{ height: "8vh" }}></img>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ maxHeight: "100%" }}>
                      <p style={{ fontSize: "2vh" }}>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{ paddingRight: "10px" }}
                        />
                        <a
                          href="mailto:info@beyondersmusic.com?subject=Beyonders Music"
                          target="_blank"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          info@beyondersmusic.com
                        </a>
                      </p>

                      <p style={{ fontSize: "2vh" }}>
                      <FontAwesomeIcon
                        icon={faInstagram}
                        style={{ paddingRight: "10px" }}
                      />
                      <a
                        href="https://www.instagram.com/beyondersmusic/"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Beyonders Music on Instagram
                      </a>
                    </p>
                    </Grid>
                  </Grid>
                </a>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <Card
              variant="outlined"
              sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            >
              <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="top"
                  justifyContent="left"
                  sx={{ minHeight: "10vh", padding: "0.5vh" }}
                >
                  <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                    <h2 style={{ borderBottom: "1px solid #555" }}>
                      Loose Agents
                    </h2>
                  </Grid>

                  <Grid item xs={12} md={4} sx={{ maxHeight: "100%" }}>
                    <img
                      src={loose_agents_logo}
                      style={{ height: "8vh" }}
                    ></img>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ maxHeight: "100%" }}>
                    <p style={{ fontSize: "2vh" }}>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ paddingRight: "10px" }}
                      />
                      <a
                        href="mailto:looseagents@beyondersmusic.com?subject=Loose Agents"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        looseagents@beyondersmusic.com
                      </a>
                    </p>
                    <p style={{ fontSize: "2vh" }}>
                      <FontAwesomeIcon
                        icon={faInstagram}
                        style={{ paddingRight: "10px" }}
                      />
                      <a
                        href="https://www.instagram.com/looseagents/"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Loose Agents on Instagram
                      </a>
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <Card
              variant="outlined"
              sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            >
              <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="top"
                  justifyContent="left"
                  sx={{ minHeight: "10vh", padding: "0.5vh" }}
                >
                  <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                    <h2 style={{ borderBottom: "1px solid #555" }}>
                      Case Edits
                    </h2>
                  </Grid>

                  <Grid item xs={12} md={4} sx={{ maxHeight: "100%" }}>
                    <img src={case_edits_logo} style={{ height: "8vh" }}></img>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ maxHeight: "100%" }}>
                    <p style={{ fontSize: "2vh" }}>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ paddingRight: "10px" }}
                      />
                      <a
                        href="mailto:caseedits@beyondersmusic.com?subject=Case Edits"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        caseedits@beyondersmusic.com
                      </a>
                    </p>
                    
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sx={{ maxHeight: "100%" }}>
            <Card
              variant="outlined"
              sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            >
              <CardContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="top"
                  justifyContent="left"
                  sx={{ minHeight: "10vh", padding: "0.5vh" }}
                >
                  <Grid item xs={12} sx={{ maxHeight: "100%" }}>
                    <h2 style={{ borderBottom: "1px solid #555" }}>
                      Joe Haydon Photography
                    </h2>
                  </Grid>

                  <Grid item xs={12} md={4} sx={{ maxHeight: "100%" }}>
                    <img src={joe_haydon_logo} style={{ height: "8vh" }}></img>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ maxHeight: "100%" }}>
                    <p style={{ fontSize: "2vh" }}>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ paddingRight: "10px" }}
                      />
                      <a
                        href="mailto:joehaydon@joehaydonphotography.co.uk?subject=Beyonders Photography"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        joehaydon@joehaydonphotography.co.uk
                      </a>
                    </p>
                    
                    <p style={{ fontSize: "2vh" }}>
                      <FontAwesomeIcon
                        icon={faInstagram}
                        style={{ paddingRight: "10px" }}
                      />
                      <a
                        href="https://www.instagram.com/joehaydonphotography/"
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Joe Haydon on Instagram
                      </a>
                    </p>

                    
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


        </Grid>
      </Container>
    </>
  );
}

export default Contact;
